import CountryRegionData from "country-region-data/data"
import {
  getCountryCallingCode,
  parsePhoneNumberFromString
} from "libphonenumber-js/min"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { getLocalizedCountryName } from "../../helpers/countries"
import { BrinkContext } from "../context/BrinkContext"
import * as events from "../context/utils/events"
import PrivacyPolicy from "../popup/PrivacyPolicy"
import TermsOfUse from "../popup/TermsOfUse"
import Button from "../ui/Button"
import Checkbox from "../ui/Checkbox"
import AdyenSessionDropin from "./AdyenSessionDropin"
import IngridShipping from "./IngridShipping"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Title = styled.p`
  text-transform: uppercase;

  span {
    color: ${(p) => p.theme.colors.darkGrey};
  }
`

const SelectContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 3.8rem;

  p {
    color: ${(p) => p.theme.colors.darkGrey};
    font-size: 1.4rem;
    margin: 0;
    position: absolute;
    top: -2.2rem;
  }

  > input {
    margin-bottom: 1.2rem;
  }
`

const Validation = styled.div`
  color: ${(p) => p.theme.colors.errors};
  display: block;
  position: absolute;
  top: 25%;
  right: 1.2rem;
  letter-spacing: 0;
  font-size: 1.3rem;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;
  }
`

const InputContainer = styled.div`
  position: relative;
`

const Input = styled.input`
  background: ${(p) => p.theme.colors.white};
  border: none;
  ${(p) => {
    return p.borders.map(
      (border) =>
        "border-" +
        border +
        ": 0.1rem solid " +
        (p.hasError ? p.theme.colors.errors : p.theme.colors.grey) +
        ";"
    )
  }}
  width: 100%;
  text-transform: initial;
  height: 4.5rem;
  padding-left: 1rem;

  &:focus {
    outline: none;
    border: 0.1rem solid ${(p) => p.theme.colors.black};
  }

  &::placeholder {
    color: ${(p) => p.theme.colors.darkGrey};
  }
`

const Select = styled.select`
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.darkGrey};
  border: 0.1rem solid ${(p) => p.theme.colors.grey};
  font-size: 2rem;
  border-radius: 0.2rem;

  appearance: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuODAzOTA2IDYuNDJMNi40NDM5MSAxMi4wNlYtOS41MzY3NGUtMDdINy41NjM5MVYxMi4wNkwxMy4yMDM5IDYuNDJMMTQuMDAzOSA3LjJMNy4wMDM5MSAxNC4yTDAuMDAzOTA2MjYgNy4yTDAuODAzOTA2IDYuNDJaIiBmaWxsPSIjOUE5QTlBIi8+Cjwvc3ZnPgoK");
  background-repeat: no-repeat;
  background-position: right 1.2rem top 50%;
  background-size: 1.2rem auto;
`

const SelectOption = styled.option`
  font-size: 1.2rem;
  background: pink;
  padding: 2rem;
  position: absolute;
  top: 0;
`

const TermsAndConditions = styled.p`
  margin: 0;
  margin-top: 2.4rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${(p) => p.theme.colors.black};
  padding-bottom: 2rem;
`

const TermsLink = styled.span`
  text-transform: none;
  color: ${(p) => p.theme.colors.black};
  font-variation-settings: "wght" 750;
  cursor: pointer;
`

const ContinueButton = styled(Button)`
  width: 100%;
  margin-top: 4rem;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  transition: all 0.2s;
  text-transform: initial;
  font-size: 2rem;
  height: 4.5rem;
  padding: 0 2.5rem;

  &:disabled {
    background: ${(p) => p.theme.colors.darkGrey};
  }

  &:hover {
    background: ${(p) => p.theme.colors.secondaryOpac};
    font-variation-settings: "wght" 700, "wdth" 700;
  }
`

const CheckboxLabel = styled.span`
  font-size: 1.6rem;
  color: ${(p) => p.theme.colors.black};
  margin-left: 1.2rem;
`

const PaymentAdyen = ({ cartHasShippingOption }) => {
  const { t } = useTranslation("translations")
  const {
    cart,
    countryWhiteList,
    languageCode,
    shippingAddress,
    setShippingAddress,
    currentStore,
    setCurrentStore,
    changeLocation,
    updateIngridShippingOption,
    shippingMethod,
    discountCode
  } = useContext(BrinkContext)
  const [showPayment, setShowPayment] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(
    currentStore.countryCode
  )
  const [showTermsOfUse, setShowTermsOfUse] = useState(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
  const [ingridOptionSelected, setIngridOptionSelected] = useState(false)
  const [regions, setRegions] = useState([])
  const [newsletterAccepted, setNewsletterAccepted] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    defaultValues: {
      email: shippingAddress.email,
      firstName: shippingAddress.firstName,
      lastName: shippingAddress.lastName,
      phone: shippingAddress.phone,
      address: shippingAddress.address,
      houseNumberOrName: shippingAddress.houseNumberOrName,
      postalCode: shippingAddress.postalCode,
      city: shippingAddress.city,
      region: shippingAddress.region
    },
    mode: "onBlur",
    reValidateMode: "onBlur"
  })

  useEffect(() => {
    if (["US", "CA"].includes(selectedCountry)) {
      const { regions } = CountryRegionData.find(
        (r) => r.countryShortCode === selectedCountry
      )
      setRegions(regions)
    } else {
      setRegions([])
    }
  }, [selectedCountry])

  const validatePhoneNumber = (data) => {
    const phoneNumber = parsePhoneNumberFromString(
      data || "",
      currentStore.countryCode
    )
    const isPossible = phoneNumber ? phoneNumber.isPossible() : false
    return isPossible || t("Invalid phone number")
  }

  const formatPhoneNumber = (data) => {
    const phoneNumber = parsePhoneNumberFromString(
      data || "",
      currentStore.countryCode
    )
    return phoneNumber ? phoneNumber.number : ""
  }

  const onContinueToPayment = () => {
    typeof window.gtag !== "undefined" &&
      window.gtag("event", "checkout_progress", {
        checkout_step: 3,
        checkout_option: "Address Form Submitted"
      })
    const formData = getValues()

    const updatedShippingAddress = {
      ...shippingAddress,
      ...formData,
      phone: formatPhoneNumber(formData.phone)
    }
    setShippingAddress(updatedShippingAddress)
    events.identify(updatedShippingAddress)
    events.addPersonalDetails(
      updatedShippingAddress.email,
      updatedShippingAddress.city,
      updatedShippingAddress.country
    )
    setShowPayment(true)
  }

  const onCountryChange = async (event) => {
    const country = event.target.value
    changeLocation(country)
  }

  const onIngridDataChanged = (data, meta) => {
    if (meta.search_address_changed) {
      const postalCode = data.search_address.postal_code
      setValue("postalCode", postalCode)
      setIngridOptionSelected(true)
      events.addShipping(
        {
          ...cart,
          cartItems: [
            ...cart.cartItems,
            {
              type: "shippingOption",
              price: {
                SEK: 0,
                EUR: 0,
                DKK: 0,
                NOK: 0,
                GBP: 0,
                CHF: 0,
                PLN: 0,
                HUF: 0,
                CZK: 0,
                USD: 0
              }
            }
          ]
        },
        { label: null },
        discountCode,
        "post code"
      )
    }
    if (
      meta.price_changed ||
      meta.shipping_method_changed ||
      meta.delivery_type_changed ||
      meta.pickup_location_changed
    ) {
      updateIngridShippingOption(
        data.search_address.country,
        data.search_address.postal_code
      ).then(() => { })
    }
  }

  const onRegionChange = (event) => {
    const region = event.target.value
    setValue("region", region)
  }

  return (
    <>
      <Wrapper>
        <Title>{t("contact")}</Title>
        <SelectContainer>
          <Input
            {...register("email", {
              required: t("This is a required field"),
              pattern: {
                value:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
                message: t("Please supply a valid e-mail address")
              }
            })}
            type="email"
            placeholder={t("E-mail")}
            borders={"left,top,right,bottom".split(",")}
          />
          {errors.email && <Validation>{errors.email.message}</Validation>}
          <Checkbox
            name="newsletter"
            value={newsletterAccepted}
            onChange={(e) => {
              setNewsletterAccepted(e.target.checked)
            }}
          >
            <CheckboxLabel>
              {t("checkout-newsletter-signup-label")}
            </CheckboxLabel>
          </Checkbox>
        </SelectContainer>
        <Title id="delivery-section">{t("delivery-options")}</Title>
        <IngridShipping
          country={selectedCountry}
          onDataChanged={onIngridDataChanged}
        />
        <Title>{t("delivery-address")}</Title>
        <form onSubmit={handleSubmit(onContinueToPayment)}>
          <InputContainer>
            <Select defaultValue={selectedCountry} onChange={onCountryChange}>
              {countryWhiteList
                ?.map((countryCode) => {
                  return (
                    <SelectOption value={countryCode} key={countryCode}>
                      {getLocalizedCountryName(countryCode, languageCode)}
                    </SelectOption>
                  )
                })
                .sort((a, b) =>
                  getLocalizedCountryName(
                    a.countryCode,
                    a.languageCode
                  )?.localeCompare(
                    getLocalizedCountryName(b.countryCode, b.languageCode)
                  )
                )}
            </Select>
          </InputContainer>
          <Row>
            <InputContainer>
              <Input
                {...register("firstName", {
                  required: t("This is a required field")
                })}
                placeholder={t("First name")}
                borders={"left,right,".split(",")}
              />
              {errors.firstName && (
                <Validation>{errors.firstName.message}</Validation>
              )}
            </InputContainer>
            <InputContainer>
              <Input
                {...register("lastName", {
                  required: t("This is a required field")
                })}
                placeholder={t("Last name")}
                borders={"right".split(",")}
              />
              {errors.lastName && (
                <Validation>{errors.lastName.message}</Validation>
              )}
            </InputContainer>
          </Row>
          <InputContainer>
            <Input
              {...register("phone", {
                required: t("This is a required field"),
                validate: (data) => validatePhoneNumber(data)
              })}
              type="tel"
              placeholder={`${t("Telephone")} (+${getCountryCallingCode(
                currentStore.countryCode
              )})`}
              borders={"left,top,right".split(",")}
            />
            {errors.phone && <Validation>{errors.phone.message}</Validation>}
          </InputContainer>
          <InputContainer>
            <Input
              {...register("address", {
                required: t("This is a required field")
              })}
              placeholder={t("Address with house number")}
              borders={"left,top,right".split(",")}
            />
            {errors.address && (
              <Validation>{errors.address.message}</Validation>
            )}
          </InputContainer>
          <InputContainer>
            <Input
              {...register("houseNumberOrName")}
              placeholder={t("House number field")}
              borders={"left,top,right".split(",")}
            />
            {errors.houseNumberOrName && (
              <Validation>{errors.houseNumberOrName.message}</Validation>
            )}
          </InputContainer>
          <Row>
            <InputContainer
              onClick={() => {
                const deliverySection =
                  document.getElementById("delivery-section")
                deliverySection.scrollIntoView({ behavior: "smooth" })
              }}
            >
              <Input
                {...register("postalCode", {
                  required: t("This is a required field")
                })}
                placeholder={t("Zip code")}
                disabled
                borders={
                  regions.length
                    ? "left,top,right".split(",")
                    : "left,top,right,bottom".split(",")
                }
                style={{
                  cursor: "pointer",
                  pointerEvents: "none",
                  color: "black",
                  borderColor: "rgb(196, 196, 196)"
                }}
              />
              {errors.postalCode && (
                <Validation>{errors.postalCode.message}</Validation>
              )}
            </InputContainer>
            <InputContainer>
              <Input
                {...register("city", {
                  required: t("This is a required field")
                })}
                placeholder={t("City")}
                borders={
                  regions.length
                    ? "top,right".split(",")
                    : "top,right,bottom".split(",")
                }
              />
              {errors.city && <Validation>{errors.city.message}</Validation>}
            </InputContainer>
            {regions.length ? (
              <Select onChange={onRegionChange}>
                <option value="">{t("Select state")}</option>
                {regions.map((r) => {
                  return (
                    <option value={r.shortCode} key={r.shortCode}>
                      {r.name}
                    </option>
                  )
                })}
              </Select>
            ) : null}
          </Row>
          {!showPayment && (
            <>
              <ContinueButton
                type="submit"
                disabled={!isValid || !ingridOptionSelected}
                onClick={onContinueToPayment}
              >
                {t("Continue to payment")}
              </ContinueButton>
              <TermsAndConditions>
                {t("By continuing you agree to COMIS’")}{" "}
                <TermsLink onClick={() => setShowTermsOfUse(true)}>
                  {t("Terms and Conditions")}{" "}
                </TermsLink>
                {t(
                  "and understand that we will process your data on the basis of our "
                )}
                <TermsLink onClick={() => setShowPrivacyPolicy(true)}>
                  {t("Privacy Policy")}
                </TermsLink>
                .
              </TermsAndConditions>
              <TermsOfUse
                openPopup={showTermsOfUse}
                setOpenPopup={setShowTermsOfUse}
              />
              <PrivacyPolicy
                openPopup={showPrivacyPolicy}
                setOpenPopup={setShowPrivacyPolicy}
              />
            </>
          )}
        </form>
      </Wrapper>
      {showPayment && (
        <AdyenSessionDropin
          newsletterChecked={newsletterAccepted}
          isShippingValid={true}
        />
      )}
    </>
  )
}

export default PaymentAdyen
