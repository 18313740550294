import React from "react"
import styled from "styled-components"

const Line = styled.div`
  width: 100%;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
`

const Divider = ({ ...other }) => <Line {...other} />

export default Divider
