import React, { useState, useContext, useEffect } from "react"
import styled, { withTheme, ThemeContext } from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../context/BrinkContext"
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "../../constants"
import Radio from "../ui/Radio"
import { Loader } from "../ui/Loader"
import Button from "../ui/Button"
import Link from "../Link"
import Divider from "../ui/Divider"
import * as events from "../../components/context/utils/events"
import { toFormat, toDinero } from "../../helpers/toDinero"

const Container = styled.div`
  display: ${(p) => (p.showPayment ? "none" : "flex")};
  flex-wrap: wrap;
  padding: 1.2rem 0;
  width: 100%;

  ${MEDIA_MIN_LARGE} {
  }

  form {
    display: flex;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.8rem;
  }

  img {
    height: 2rem;
    margin-right: 1rem;
    max-width: 9rem;
    padding-top: 0.2rem;
  }
`

const SummaryTitle = styled.h3`
  font-size: 2rem !important;
  margin: 2rem 0 !important;

  span {
    color: ${(p) => p.theme.colors.darkGrey};
  }
`

const EditLink = styled(Link)`
  flex-shrink: 0;
  position: absolute;
  right: 1.5rem;
  font-size: 1.6rem;
  margin: 2rem 0 !important;

  &:after {
    bottom: -0.8rem;
  }
`

const Title = styled.h3`
  font-size: 2rem !important;
  margin: 1rem 0 4rem 0 !important;

  span {
    color: ${(p) => p.theme.colors.darkGrey};
  }
`

const ShippingOptions = styled.div`
  position: relative;
  min-height: 10rem;
  width: 100%;
`

const ShippingOption = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border: 0.1rem solid
    ${(p) => (p.selected ? p.theme.colors.black : p.theme.colors.grey)};
  border-radius: 0.3rem;
  padding: 1.2rem;
  cursor: pointer;
  margin-bottom: 1.2rem;
`

const StyledRadio = styled(Radio)`
  height: 4rem;

  input {
    margin: 0 1.5rem 2.1rem 0;
  }
`

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem 0 0 0;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Price = styled.span`
  font-size: 2rem;
  margin-right: 3rem;

  ${MEDIA_MIN_MEDIUM} {
    margin-right: 4rem;
  }
`

const Image = styled(GatsbyImage)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transform: translateZ(0);
`

const Name = styled.span`
  width: 100%;
  font-size: 2rem;
`

const Description = styled.span`
  color: ${(p) => p.theme.colors.darkGrey};
  width: 100%;
  font-size: 1.6rem;
`

const ContinueButton = styled(Button)`
  width: 100%;
  margin: 1rem 0;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  transition: all 0.2s;
  text-transform: initial;
  font-size: 2rem;

  &:disabled {
    background: ${(p) => p.theme.colors.darkGrey};
    pointer-events: none;
  }

  &:hover {
    background: ${(p) => p.theme.colors.secondaryOpac};
    font-variation-settings: "wght" 700, "wdth" 700;
  }
`

const Shipping = withTheme(
  ({
    sanityShippingOptions,
    showPayment,
    setShowPayment,
    cartHasShippingOption,
    showSummary,
    setShowShipping
  }) => {
    const {
      languageCode,
      getShippingOptions,
      currentStore,
      cart,
      discountCode,
      setShippingMethod,
      shippingMethod
    } = useContext(BrinkContext)
    const [shippingOptions, setShippingOptions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation("translations")
    const { colors } = useContext(ThemeContext)

    const handleContinueClick = () => {
      events.addShipping(cart, shippingMethod, discountCode)
      setShowPayment(true)
    }

    const useMountEffect = (fun) =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(fun, [])

    useMountEffect(() => {
      const initializeShippingOptions = async () => {
        setIsLoading(true)
        const availableShippingOptions = await getShippingOptions(
          currentStore.countryCode
        )
        setShippingOptions(
          sanityShippingOptions.nodes
            .map((shippingOption) =>
              toShippingOption(availableShippingOptions, shippingOption)
            )
            .filter((x) => x)
            .sort((a, b) => a.sortOrder - b.sortOrder)
        )
      }
      initializeShippingOptions().finally(() => setIsLoading(false))
    })

    useEffect(() => {
      if (shippingOptions.length > 0 && !cartHasShippingOption) {
        setShippingMethod(shippingOptions[0])
        setShowPayment(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartHasShippingOption, shippingOptions])

    const toShippingOption = (shippingOptions, shippingOption) => {
      const availableShippingOption = shippingOptions.find(
        (o) => o.id === shippingOption.id
      )
      return availableShippingOption
        ? {
            ...shippingOption,
            price: availableShippingOption.price
          }
        : undefined
    }

    const handleShippingClick = (id) => {
      setShippingMethod(shippingOptions.find((o) => o.id === id))
    }

    const isPreSelected = (shippingOption) =>
      shippingMethod && shippingOption.id === shippingMethod.id

    return (
      <>
        <Container showPayment={showPayment}>
          <Title>
            {t("Shipping")} <span>(2/3)</span>
          </Title>
          <ShippingOptions>
            {isLoading && <Loader isLoading={isLoading} color="black" />}
            {shippingOptions.map((shippingOption) => {
              const { mainImage, displayName, description, price } =
                shippingOption

              const priceDinero = toDinero(
                price[currentStore.currencyUnit],
                currentStore.currencyUnit
              )
              return (
                <ShippingOption
                  key={shippingOption.id}
                  onClick={() => handleShippingClick(shippingOption.id)}
                  htmlFor={shippingOption.id}
                  selected={isPreSelected(shippingOption)}
                >
                  <StyledRadio
                    id={shippingOption.id}
                    name="shipping"
                    preSelected={
                      isPreSelected(shippingOption) ? "true" : undefined
                    }
                  />
                  <Details>
                    <Name>
                      <span>{displayName[languageCode] || displayName.en}</span>
                    </Name>
                    <Description>
                      {description[languageCode] || description.en}
                    </Description>
                  </Details>
                  <Wrapper>
                    <Price>
                      {price[currentStore.currencyUnit] !== 0
                        ? toFormat(priceDinero)
                        : t("Free")}
                    </Price>
                    <Image
                      image={getImage(mainImage?.asset)}
                      alt={displayName[languageCode] || displayName.en}
                    />
                  </Wrapper>
                </ShippingOption>
              )
            })}
          </ShippingOptions>
          <ContinueButton
            onClick={handleContinueClick}
            disabled={!shippingMethod}
          >
            {t("Continue to payment")}
          </ContinueButton>
        </Container>
        {showSummary && showPayment && (
          <>
            <Row>
              <>
                <SummaryTitle>
                  {t("Shipping")} <span>(2/3)</span>
                </SummaryTitle>
              </>
              <EditLink
                to="#"
                color={colors.black}
                onClick={(e) => {
                  e.preventDefault()
                  setShowPayment(false)
                  setShowShipping(true)
                }}
              >
                {t("Edit")}
              </EditLink>
            </Row>
            <Divider />
          </>
        )}
      </>
    )
  }
)

export default Shipping
