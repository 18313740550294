import "@adyen/adyen-web/dist/adyen.css"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import "../../styles/adyen.css"
import { BrinkContext } from "../context/BrinkContext"
import * as events from "../context/utils/events"
import { subscribeToNewsletter } from "../context/utils/events/klaviyo"
import { subscribeToNewsletter as pinterestTagSubscribeToNewsletter } from "../context/utils/events/pinterestTag"
import Button from "../ui/Button"
import { Loader } from "../ui/Loader"

const CHECKOUT_NEWSLETTER_LIST_ID = "VFv9DU"

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.colors.errors};
  border: 0.1rem solid ${(p) => p.theme.colors.errors};
  padding: 1.5rem 2rem;
  letter-spacing: 0;
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.8rem;
`

const DropinContainer = styled.div`
  .adyen-checkout__threeds2__challenge,
  .adyen-checkout__iframe {
    width: 100%;
  }

  .adyen-checkout__iframe {
    margin: 0 2rem;
  }
`

const RetryButton = styled(Button)`
  background: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  margin-top: 1rem;
`

export const AdyenSessionDropin = ({
  isShippingValid,
  newsletterChecked = false
}) => {
  const { t } = useTranslation("translations")
  const {
    ADYEN_ENVIRONMENT,
    ADYEN_CLIENT_KEY,
    cartToAdyenOrder,
    cart,
    shippingAddress,
    currentStore,
    languageCode,
    setOrder,
    closeCart,
    setIsLoading,
    isLoading,
    setIsCartOpen,
    discountCode
  } = useContext(BrinkContext)
  const [displayError, setDisplayError] = useState(false)
  const [showCheckout, setShowCheckout] = useState(true)
  const [showRetryButton, setShowRetryButton] = useState(false)
  const [payPalActions, setPayPalActions] = useState(null)
  const [localOrder, setLocalOrder] = useState(null)
  const [showTermsOfUse, setShowTermsOfUse] = useState(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)

  const paymentMethod = useRef("")

  const {
    control,
    handleSubmit,
    triggerValidation,
    formState: { isValid }
  } = useForm({ mode: "onBlur" })

  const handleResult = (response, order) => {
    if (response.resultCode === "Authorised") {
      setOrder(order)
      closeCart()
      setIsCartOpen(false)
      navigate(`/${currentStore.countryCode.toLowerCase()}/success/`, {
        state: {
          order,
          cart,
          billingAddress: shippingAddress,
          resultCode: response.resultCode,
          paymentMethod: paymentMethod.current
        }
      })
    } else {
      setShowCheckout(false)
      setDisplayError(true)
      setShowRetryButton(true)
    }
  }

  const dropin = useRef(null)
  const paypalComponent = useRef(null)

  useEffect(() => {
    if (!payPalActions) return
    if (isValid) {
      payPalActions.enable()
    } else {
      payPalActions.disable()
    }
  }, [payPalActions, isValid])

  useEffect(() => {
    showRetryButton && dropin.current.unmount()
  }, [showRetryButton])

  useEffect(() => {
    if (!shippingAddress || !isShippingValid) return

    const generateAdyenPayment = async () => {
      setIsLoading(true)
      const { adyen, brink } = await cartToAdyenOrder()
      const order = brink.order
      setLocalOrder(order)
      const session = adyen.session
      const configuration = {
        session: {
          id: session.id,
          sessionData: session.sessionData
        },
        clientKey: ADYEN_CLIENT_KEY,
        locale: languageCode,
        environment: ADYEN_ENVIRONMENT,
        showPayButton: true,
        onPaymentCompleted: (result, component) => {
          console.info(result, component)
          handleResult(result, order)
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            hideCVC: false,
            name: t("Credit or debit card")
          }
        },
        applepay: {
          countryCode: currentStore.countryCode
        }
      }

      import("@adyen/adyen-web")
        .then(({ default: AdyenCheckout }) => AdyenCheckout)
        .then((AdyenCheckout) => AdyenCheckout(configuration))
        .then((checkout) => {
          if (dropin.current) {
            dropin.current.unmount()
          }
          dropin.current = checkout
            .create("dropin", {
              onSelect: ({ props: { type } }) => {
                paymentMethod.current = type
              }
            })
            .mount("#dropin-container")

          paypalComponent.current = checkout.create("paypal", {
            showPayButton: true,
            onInit: (_, actions) => {
              actions.disable()
              setPayPalActions(actions)
            },
            onClick: () => {
              triggerValidation()
              sendEventsOnPlacedOrder("paypal")
            },
            onCancel: () => setShowCheckout(true),
            onError: () => setShowCheckout(true)
          })
        })
        .catch((error) => console.error(error))
    }

    generateAdyenPayment().finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingAddress, cart.cartItems, isShippingValid])

  const sendEventsOnPlacedOrder = (paymentMethod) => {
    if (newsletterChecked) {
      subscribeToNewsletter(
        currentStore.countryCode,
        shippingAddress.email,
        CHECKOUT_NEWSLETTER_LIST_ID
      )
      pinterestTagSubscribeToNewsletter()
      events.signup({
        email: shippingAddress.email,
        pageLocation: "/checkout/"
      })
    }
    events.addPaymentInfo(cart, paymentMethod, discountCode)
  }

  return (
    <>
      {isLoading && <Loader isLoading={isLoading} color="black" />}
      {displayError && (
        <ErrorMessage>
          {t(
            "Something went wrong with your payment. Please try another option or contact our support."
          )}
        </ErrorMessage>
      )}
      {showRetryButton && (
        <>
          <RetryButton
            onClick={() => {
              setDisplayError(false)
              setShowCheckout(true)
              setShowRetryButton(false)
              dropin.current.update()
            }}
          >
            {t("Retry")}
          </RetryButton>
        </>
      )}

      <DropinContainer id="dropin-container" />
    </>
  )
}

export default AdyenSessionDropin
