import { navigate, useLocation } from "@reach/router"
import { Script, graphql } from "gatsby"
import jwt_decode from "jwt-decode"
import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import styled, { ThemeProvider } from "styled-components"
import Head from "../components/Head"
import Header from "../components/checkout/Header"
import {
  OrderSummary,
  OrderSummaryAccordion
} from "../components/checkout/OrderSummary"
import Payment from "../components/checkout/Payment"
import { BrinkContext } from "../components/context/BrinkContext"
import { PageProvider } from "../components/context/PageContext"
import * as events from "../components/context/utils/events"
import { MEDIA_MIN_LARGE } from "../constants"
import { toDineroFormatted } from "../helpers/toDinero"
import { theme } from "../theme"

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 7.4rem 0 4rem;
`

const Body = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background: ${(p) => p.theme.colors.background};

  ${MEDIA_MIN_LARGE} {
    margin: 0 auto;
  }

  h1 {
    width: 100%;
    margin: 1rem 3rem 4rem;

    ${MEDIA_MIN_LARGE} {
      margin: 6rem 3rem 6rem;
    }
  }
`

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  max-width: 55rem;

  ${MEDIA_MIN_LARGE} {
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 100%;
  }
`

const GridItem = styled.div`
  width: 100%;
  padding: 0 1.2rem;

  ${MEDIA_MIN_LARGE} {
    padding: 0;
  }
`

const Left = styled(GridItem)`
  order: 2;

  ${MEDIA_MIN_LARGE} {
    order: 1;
    width: 55rem;
    margin-right: 8rem;
  }
`

const Right = styled(GridItem)`
  order: 1;

  ${MEDIA_MIN_LARGE} {
    order: 2;
    width: 36rem;
  }
`

const Title = styled.h3`
  text-transform: initial;
  font-size: 3rem;
  margin-block: 2rem;
`

const OrderSummaryWrapper = styled.div`
  display: block;
  position: sticky;
  z-index: 100;
  top: 7.4rem;
  background: white;

  h3 {
    margin: 0;
  }

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const PaymentFailure = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  width: 100%;
  margin-bottom: 3rem;
  background: #f9c7c5;
  color: #9e362c;

  i {
    font-size: 3rem;
    margin-right: 1rem;
  }
`

const Sticky = styled.div`
  height: 100%;
  max-height: calc(100vh - 7.3rem);
  padding: 1.2rem 0;

  ${MEDIA_MIN_LARGE} {
    position: sticky;
    top: 3rem;
    border: 0.1rem solid ${(p) => p.theme.colors.grey};
    width: 36rem;
    height: auto;
    padding: 1.2rem;
  }
`

const DesktopOnly = styled.div`
  display: none;
  ${MEDIA_MIN_LARGE} {
    display: block;
  }
`

const INTRO_SET_PRODUCT_ID = "755acf15-eef7-433a-bee3-4f64098fdf68"

const CheckoutPage = ({
  data: { sanityConfigUpsell, sanityCheckoutUsp, allSanityShippingOption },
  pageContext
}) => {
  const { t } = useTranslation("translations")
  const location = useLocation()
  const {
    cart,
    currentStore,
    getSetCart,
    brinkSessionId,
    listName,
    languageCode
  } = useContext(BrinkContext)

  const { sanityCheckoutTitle } = pageContext

  const isBrowser = typeof window !== `undefined`

  useEffect(() => {
    if (isBrowser) window.Freshbots?.deActivate()
  }, [isBrowser])

  const getCart = async (token) =>
    await getSetCart(jwt_decode(token).sessionId, token)

  const isEmptyCart = (cart) => {
    if (!cart) return true
    return (
      cart.cartItems.filter((p) => p.type === "productVariant").length === 0
    )
  }

  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])

  useMountEffect(() => {
    const startCheckout = async () => {
      const params = new URLSearchParams(location.search)
      let storedCart = cart
      const token = params.get("cart")
      if (token) {
        storedCart = await getCart(token)
      }

      if (!isEmptyCart(storedCart)) {
        events.initiateCheckout(
          storedCart,
          currentStore,
          brinkSessionId,
          listName
        )
      }
    }
    startCheckout()
  })

  useEffect(() => {
    if (cart?.cartItems.length === 0) {
      navigate("/" + currentStore.countryCode.toLowerCase())
    }
  }, [cart])

  const cartHasShippingOption = cart.cartItems.find(
    (p) => p.type === "shippingOption"
  )

  const cartHasIntroSet = cart.cartItems.some(
    (p) => p.productId === INTRO_SET_PRODUCT_ID
  )

  const price = toDineroFormatted(
    cart.totalPriceWithDiscount || 0,
    currentStore.currencyUnit
  )

  return (
    <ThemeProvider theme={theme(pageContext.sanityTheme)}>
      <PageProvider pageContext={pageContext}>
        {cart && cart.cartItems.length > 0 && (
          <Wrapper className="top">
            <Script defer="" src="https://s.apprl.com/js/apprl.js" />
            <Helmet>
              <html lang="en" className="noOverflow" />
              <body className="noOverflow" />
            </Helmet>
            <Head
              meta={{
                title:
                  sanityCheckoutTitle.title[languageCode] ||
                  sanityCheckoutTitle.title.en
              }}
            />
            <Header />
            <OrderSummaryWrapper>
              <OrderSummaryAccordion title={t("Your order")} price={price}>
                <OrderSummary
                  {...{
                    cart,
                    sanityConfigUpsell,
                    cartHasIntroSet,
                    sanityCheckoutUsp
                  }}
                />
              </OrderSummaryAccordion>
            </OrderSummaryWrapper>
            <Body>
              {location.state && location.state.error === "payment-failure" && (
                <PaymentFailure>
                  <i className="fal fa-exclamation-circle"></i>
                  {t(
                    "Something went wrong with your payment. Please try another option or contact our support."
                  )}
                </PaymentFailure>
              )}
              <Grid>
                <Left>
                  <Title>
                    {sanityCheckoutTitle.title[languageCode] ||
                      sanityCheckoutTitle.title.en}
                  </Title>
                  <Payment
                    cartHasShippingOption={cartHasShippingOption}
                    allSanityShippingOption={allSanityShippingOption}
                  />
                </Left>
                <Right>
                  <DesktopOnly>
                    <Title>{t("Your order")}</Title>
                    <Sticky>
                      <OrderSummary
                        {...{
                          cart,
                          sanityConfigUpsell,
                          cartHasIntroSet,
                          sanityCheckoutUsp
                        }}
                      />
                    </Sticky>
                  </DesktopOnly>
                </Right>
              </Grid>
            </Body>
          </Wrapper>
        )}
      </PageProvider>
    </ThemeProvider>
  )
}

export default CheckoutPage

export const query = graphql`
  query {
    sanityConfigUpsell {
      upsellEnabled
      upsellTitle
      upsellProducts {
        variants {
          _id
          mainImage {
            asset {
              gatsbyImageData(width: 150)
            }
          }
          displayName {
            en
            sv
            de
            fr
          }
          children {
            id
            ... on BrinkProductVariant {
              price {
                amount
                currencyUnit
              }
            }
          }
        }
      }
    }
    sanityCheckoutUsp {
      uspEntries {
        de
        en
        fr
        sv
      }
    }
    allSanityShippingOption {
      nodes {
        id: _id
        displayName {
          en
          sv
          de
          fr
        }
        description {
          en
          sv
          de
          fr
        }
        sortOrder
        mainImage {
          asset {
            gatsbyImageData(width: 150)
          }
        }
      }
    }
  }
`
