import React, { useContext, useEffect, useRef, useState } from "react"
import styled, { withTheme } from "styled-components"
import { MEDIA_MIN_LARGE } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import { Loader } from "../ui/Loader"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.4rem;
  width: 100%;
  ${MEDIA_MIN_LARGE} {
  }
  form {
    display: flex;
  }
`
const ShippingOptions = styled.div`
  position: relative;
  min-height: 10rem;
  width: 100%;
  iframe {
    form {
      display: none;
    }
  }
`

const IngridShipping = withTheme(({ country, onDataChanged = () => {} }) => {
  const {
    getStoreFromCountryCode,
    updateStoreInCart,
    updateIngridShippingOption
  } = useContext(BrinkContext)
  const [isLoading, setIsLoading] = useState(false)
  const widgetWrapperRef = useRef(null)
  const isScriptNode = (node) => {
    return node.tagName === "SCRIPT"
  }
  const isExternalScript = (node) => {
    return !!node.src && node.src !== ""
  }
  const cloneScriptNode = (node) => {
    const script = document.createElement("script")
    script.text = node.innerHTML
    for (let i = node.attributes.length - 1; i >= 0; i--) {
      script.setAttribute(node.attributes[i].name, node.attributes[i].value)
    }
    return script
  }
  const replaceScriptNode = (node) => {
    if (isScriptNode(node) && !isExternalScript(node)) {
      if (node.parentNode) {
        node.parentNode.replaceChild(cloneScriptNode(node), node)
      }
    } else {
      let i = 0
      const children = node.childNodes
      while (i < children.length) {
        replaceScriptNode(children[i++])
      }
    }
    return node
  }

  const handleIngridInitialize = (htmlSnippet) => {
    widgetWrapperRef.current.innerHTML = htmlSnippet
    replaceScriptNode(document.getElementById("shipwallet-container"))
    window._sw((api) => {
      api.on("data_changed", (data, meta) => {
        onDataChanged(data, meta)
      })
    })
  }

  useEffect(() => {
    const loadIngrid = async () => {
      setIsLoading(true)
      try {
        const store = getStoreFromCountryCode(country)
        await updateStoreInCart(store)
        const shipping = await updateIngridShippingOption(country, "")
        handleIngridInitialize(shipping.ingrid.htmlSnippet)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error("IngridShipping useEffect", error)
      }
    }
    loadIngrid()
    return () => {
      if (widgetWrapperRef.current) {
        widgetWrapperRef.current.innerHTML = ""
      }
    }
  }, [country])

  return (
    <>
      <Container>
        <ShippingOptions>
          {isLoading && <Loader isLoading={isLoading} color="black" />}
          <div ref={widgetWrapperRef}></div>
        </ShippingOptions>
      </Container>
    </>
  )
})
export default IngridShipping
